








































































import BaseToolbar from '@/components/core/BaseToolbar.vue';
import { Component, Watch } from 'vue-property-decorator';
import moment from 'moment-timezone';
import CommonUtils from '@/utils/common-utils';
import { namespace } from 'vuex-class';

const settings = namespace('settings');

  @Component({
    components: {
      BaseToolbar
    }
  })
export default class AttendanceToolbar extends BaseToolbar {
  @settings.Getter('getGlobalLanguage')
  globalLanguage!: any;

  @settings.Getter
  highContrastMode!: boolean;

  showDatePicker = false;

  @Watch('showDatePicker')
  focusDatePicker() {
    if (this.showDatePicker) {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 100)).then(() => {
          try {
            (this.$refs as any).datePicker.$el.querySelector('.v-date-picker-header__value').querySelector('button').focus()
          } catch (e) {
            console.log(this.$t('focusFailed'));
          }
        });
      });
    }
  }

  get localAttendanceComponent(): any {
    return this.currentPageInstance || {};
  }

  get lessonMenuColor() {
    return this.localAttendanceComponent.lessonMenuColor || '';
  }

  get lessonMenuLabel() {
    return this.localAttendanceComponent.lessonMenuLabel || '';
  }

  get isLessonsEmpty() {
    return CommonUtils.get(this.localAttendanceComponent.isLessonsEmpty, true);
  }

  get selectedLessonIdx() {
    return this.localAttendanceComponent.selectedLessonIdx || 0;
  }

  set selectedLessonIdx(value: number) {
    this.localAttendanceComponent.selectedLessonIdx = value;
  }

  get date() {
    return this.localAttendanceComponent.date || moment().format('YYYY-MM-DD');
  }

  set date(value: any) {
    this.localAttendanceComponent.date = value;
  }

  get dateText() {
    return this.localAttendanceComponent.dateText || '';
  }

  get refresh() {
    return this.localAttendanceComponent.refresh;
  }

  get lessons() {
    return this.localAttendanceComponent.lessons;
  }

  get searchText() {
    return this.localAttendanceComponent.studentSearch || '';
  }

  set searchText(value: string) {
    this.localAttendanceComponent.studentSearch = value;
  }

  get isMenuOverlapping() {
    return this.localAttendanceComponent.isMenuOverlapping || this.emptyFunction;
  }

  get localIsMenuOverlapping() {
    return this.localAttendanceComponent.localIsMenuOverlapping || false;
  }

  get getGlobalLanguage() {
    return this.globalLanguage || 'en';
  }
}
